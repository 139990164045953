import React, { useState } from "react";
import { Card } from "react-bootstrap/esm";
import { Button } from "react-bootstrap";
import BigNumber from "bignumber.js";
import { AppParams } from "../../config";
import { useRecoilValue } from "recoil";
import { accountState, web3State, wrongNetworkState } from "../../recoil/atoms";
import marketplaceAbi from "../../data/Abis/NFTMarketplaceAbi.json";
import Loader from "../../UI/Loader";
import SimpleMessage from "../SimpleMessage";
const NftDetails = ({
  name = "Anonymous",
  currentSupp = 0,
  maxSupply = 0,
  price = 0,
  categoryId,
  projectId,
}) => {
  const web3Api = useRecoilValue(web3State);
  const { account } = useRecoilValue(accountState);
  const [purchaseState, setPurchaseState] = useState({
    isLoading: false,
    success: false,
    error: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(null);
  const wrongNetwork = useRecoilValue(wrongNetworkState);
  const clickHandler = async () => {
    setPurchaseState((prevState) => {
      return { ...prevState, isLoading: true, success: false, error: "" };
    });
    try {
      const platformTokenContract = new web3Api.web3.eth.Contract(
        AppParams.NETWORK.platformToken.abi,
        AppParams.NETWORK.platformToken.address
      );

      const allowed = await platformTokenContract.methods
        .allowance(account, AppParams.NETWORK.nftMarketplaceAddress)
        .call();
      if (
        +web3Api.web3.utils.fromWei(allowed.toString()) <
        +web3Api.web3.utils.fromWei(price.toString())
      ) {
        await platformTokenContract.methods
          .approve(AppParams.NETWORK.nftMarketplaceAddress, price)
          .send({ from: account });
      }

      const marketplaceContract = new web3Api.web3.eth.Contract(
        marketplaceAbi,
        AppParams.NETWORK.nftMarketplaceAddress
      );

      await marketplaceContract.methods
        .buyNft(parseFloat(projectId), parseFloat(categoryId))
        .send({ from: account });
      setPurchaseState((prevState) => {
        return { ...prevState, isLoading: false, success: true };
      });

      setShowModal(true);
      setMessage(`${name} NFT bought successfully`);
    } catch (error) {
      if (error.code !== 4001) {
        setPurchaseState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
            success: false,
            error: "Something went wrong, please try later",
          };
        });
        setMessage("Something went wrong, please try later");
        setShowModal(true);
      } else {
        setPurchaseState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      }
    }
  };
  return (
    <Card className="w-100 mb-3 cP">
      <Card.Body>
        <Card.Text className="alignTc">
          <span className="  fw-boldT me-1 d-inline-block">Category Name:</span>
          <span>{name}</span>
        </Card.Text>
        <Card.Text className="alignTc">
          <span className="  fw-boldT me-1 d-inline-block">
            Current Supply:
          </span>
          <span>{currentSupp}</span>
        </Card.Text>
        <Card.Text className="alignTc">
          <span className="  fw-boldT me-1 d-inline-block">
            Maximum Supply:
          </span>
          <span>{maxSupply}</span>
        </Card.Text>
        <Card.Text className="alignTc">
          <span className="  fw-boldT me-1 d-inline-block">Price:</span>
          <span>
            {`${new BigNumber(price.toString())
              .dividedBy(
                new BigNumber("10").pow(
                  AppParams.NETWORK.platformToken.decimals.toString()
                )
              )
              .toString()}
            ${AppParams.NETWORK.platformToken.symbol}`}
          </span>
        </Card.Text>

        <Card.Text
          className="  alignTc text-center"
          style={{ justifyContent: "center" }}
        >
          <span>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eos
            voluptatum ea veniam quisquam consectetur nulla libero et optio quod
            sed minima minus autem unde cupiditate explicabo dignissimos,
            necessitatibus sunt amet animi. Corporis modi impedit ducimus
            deleniti! Culpa a saepe esse.
          </span>
        </Card.Text>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-center align-items-center">
        <Button
          className="btn-sm"
          onClick={clickHandler}
          disabled={
            !account ||
            wrongNetwork ||
            purchaseState.isLoading ||
            +currentSupp >= +maxSupply
          }
        >
          {purchaseState.isLoading ? (
            <span>
              <span className="d-inline-block me-2">Loading</span>
              <Loader width={16} height={16} />
            </span>
          ) : (
            "Buy Now"
          )}
        </Button>
      </Card.Footer>
      {showModal && (
        <SimpleMessage
          show={showModal}
          message={message}
          onHide={() => {
            setShowModal(false);
            setMessage("");
          }}
        />
      )}
    </Card>
  );
};

export default NftDetails;
