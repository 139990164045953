import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import About from "./pages/About";
import Header from "./components/header/Header";
import ErrorPage from "./pages/ErrorPage";
import { accountState, web3State } from "./recoil/atoms";
import { useRecoilValue } from "recoil";
import admin from "./data/admin.json";
import useWeb3 from "./hooks/useWeb3";
import LoadingPage from "./pages/LoadingPage";
import ProjectNftsPage from "./pages/ProjectNftsPage";
import UserWallet from "./pages/UserWallet";
const Home = lazy(() => import("./pages/Home"));
const AddProject = lazy(() => import("./pages/AddProject"));
const SingleProductPage = lazy(() => import("./pages/SingleProjectPage"));
const NftMembership = lazy(() => import("./pages/NftMembership"));
const Projects = lazy(() => import("./pages/Projects"));

function App() {
  useWeb3();
  const web3 = useRecoilValue(web3State);
  const { account, accountIsReady } = useRecoilValue(accountState);
  const web3Api = useRecoilValue(web3State);
  return (
    <>
      <Header />
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/nftmembership" element={<NftMembership />} />
          <Route path="/projects" element={<Projects />} />{" "}
          <Route
            path="/addproject"
            element={
              (!account && accountIsReady && !web3.isLoading && web3.isReady) ||
              (account && !admin[account]) ? (
                <Home />
              ) : (
                <AddProject />
              )
            }
          />
          <Route path="/projectNft/:id" />
          <Route path="/project/:id" element={<SingleProductPage />} />
          <Route path="/nfts/:projectId/:id" element={<ProjectNftsPage />} />
          <Route
            path="/userwallet"
            element={
              web3Api.isLoading || !accountIsReady ? (
                <LoadingPage />
              ) : accountIsReady && account ? (
                <UserWallet />
              ) : (
                <Home />
              )
            }
          />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
