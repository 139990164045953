import React from "react";
import { Button, Modal } from "react-bootstrap";

const SimpleMessage = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{ textAlign: "center" }}>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="outline-primary">
          ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SimpleMessage;
