import React from "react";
import { Col, Row } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { accountState } from "../../recoil/atoms";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { createClient } from "urql";
import { AppParams } from "../../config";

import classes from "./Collection.module.css";
import Loader from "../../UI/Loader";
import CollectionNft from "./CollectionNft";

const walletClient = createClient({ url: AppParams.GRAPH_URL });
const Collection = () => {
  const { account, accountIsReady } = useRecoilValue(accountState);
  const [tokens, setTokens] = useState({
    isLoading: null,
    success: null,
    error: "",
    tokens: null,
  });

  const getData = useCallback(async () => {
    if (account) {
      let query = `{
  users(where:{id:"${account.toLowerCase()}"}){id,tokens{id,tokenId,project{id,tokenName},tokenCategory{id,name,price,contract{contractAddress}}}}
}`;
      try {
        setTokens((prevState) => {
          return {
            ...prevState,
            isLoading: true,
            success: false,
            error: "",
          };
        });
        const response = await walletClient.query(query).toPromise();

        if (response.error) {
          throw new Error("Something went wrong please try later");
        }
        setTokens((prevState) => {
          return {
            ...prevState,
            isLoading: false,
            success: true,
            tokens:
              response.data.users.length !== 0
                ? response.data.users[0].tokens
                : null,
          };
        });
      } catch (error) {
        setTokens((prevState) => {
          return {
            ...prevState,
            isLoading: false,
            success: false,
            error: "Something went wrong,please try later",
          };
        });
      }
    }
  }, [account]);

  useEffect(() => {
    if (account && accountIsReady) {
      getData();
    }
  }, [getData, account, accountIsReady]);

  return (
    <section className=" w-100">
      <h1 className="h2 mt-3">My collection</h1>
      {tokens.isLoading && (
        <div
          className={`${classes["loader-container"]} d-flex justify-content-center align-items-center`}
        >
          <Loader width={"26"} height={"26"} dark />
        </div>
      )}
      {tokens.error && (
        <div
          className={`${classes["loader-container"]} d-flex justify-content-center align-items-center text-danger`}
        >
          {tokens.error}
        </div>
      )}
      {tokens.success && (!tokens.tokens || tokens.length === 0) && (
        <div
          className={`${classes["loader-container"]} d-flex justify-content-center align-items-center `}
        >
          <p>You didn't buy any NFT yet!</p>
        </div>
      )}
      {tokens.success && tokens.tokens && tokens.length !== 0 && (
        <Row className="gy-4 justify-content-center w-100">
          {tokens.tokens.map((token) => {
            return (
              <Col md="4" xl="3" key={token.id}>
                <CollectionNft {...token} />
              </Col>
            );
          })}
        </Row>
      )}
    </section>
  );
};

export default Collection;
