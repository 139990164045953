import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      <section className="justify-centent-center align-items-start section pt-5">
        <div>
          <div className="bgTitle">
            <h1 className="text-white">About us</h1>
          </div>
          <Container className="container-lg">
            <p className="pt-4 mt-3">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Repellendus ratione nostrum aspernatur deserunt voluptas maiores
              alias labore inventore debitis, qui, cum odio, minus ipsa modi
              molestiae cupiditate atque nesciunt aut a magnam corrupti
              voluptatum quisquam tempore enim? Inventore, excepturi?
              Architecto!
            </p>
          </Container>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
