import React from "react";

import classes from "./NftDetails.module.css";

const ImageContainer = ({ imageUrl }) => {
  return (
    <div className={`${classes["img-container"]} shadow `}>
      <img src={imageUrl} alt="Nft" />
    </div>
  );
};

export default ImageContainer;
