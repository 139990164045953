import React from "react";
import classes from "./Userclass.module.css";
const UserClass = ({ image, text }) => {
  return (
    <div className="d-flex  justify-content-between align-items-center">
      <img src={image} alt="class logo" className={classes.img} />
      <span className="d-inline-block text-capitalise ms-1">{text}</span>
    </div>
  );
};

export default UserClass;
