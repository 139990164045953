import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  accountState,
  nftWalletState,
  wrongNetworkState,
} from "../../recoil/atoms";

import classes from "./MemberMessage.module.css";

const MemberMessage = React.memo(() => {
  const nftWallet = useRecoilValue(nftWalletState);
  const { account, isLoading, accountIsReady } = useRecoilValue(accountState);
  const wrongNetwork = useRecoilValue(wrongNetworkState);

  return (
    <section className="w-100 ">
      {!wrongNetwork &&
        account &&
        accountIsReady &&
        nftWallet.isReady &&
        nftWallet.wallet.bronze === 0 &&
        nftWallet.wallet.silver === 0 &&
        nftWallet.wallet.gold === 0 && (
          <div className={`${classes["text-container"]}  text-light p-2`}>
            <div className="container-lg d-flex justify-content-center align-items-center">
              <p className=" m-0 me-3">
                Become a member to be able to participate to our IDOs
              </p>
              <div className={`${classes.btn} rounded memberM `}>
                <Link
                  to={"/nftmembership"}
                  className="text-decoration-none text-capitalize "
                  style={{ fontSize: 12, color: "#2c5f99" }}
                >
                  Join NOW
                </Link>
              </div>
            </div>
          </div>
        )}
    </section>
  );
});

export default MemberMessage;
