import { useCallback } from "react";
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { accountState, isLoggedInState } from "../recoil/atoms";
import setLocalStorage from "../utils/setLocalStorage";

import useWeb3 from "./useWeb3";

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const { connectWallet } = useWeb3();

  const setAccount = useSetRecoilState(accountState);

  const logIn = useCallback(() => {
    setIsLoggedIn(true);
    connectWallet();
  }, [connectWallet, setIsLoggedIn]);
  const logOut = useCallback(() => {
    setIsLoggedIn(false);
    setAccount((prevState) => {
      return { ...prevState, account: null, accountIsReady: true };
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setLocalStorage("isLoggedIn", true);
    } else if (!isLoggedIn) {
      setLocalStorage("isLoggedIn", false);
    }
  }, [isLoggedIn]);
  return { logIn, logOut };
};

export default useAuth;
