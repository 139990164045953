import network from "./platformMainNetworkDev.json";
export const AppParams_dev = {
  BACKEND_URL: "https://o2b-launchpad-nft-dev.onrender.com",
  WS_BACKEND: "wss://api-launchpad.o2bprcl.xyz/register-websocket",
  BLOCKPASS_ID: "mygenioosblockpass_d0cb7",
  NETWORK: network,
  GRAPH_URL:
    "https://api.thegraph.com/subgraphs/name/open2be-launchpad-dev/launchpad-nft-dev",
  NFT_GRAPH_URL: "",
};
