import React from "react";
import classes from "./Header";
import { NavLink } from "react-router-dom";
import admin from "../../data/admin.json";
const Nav = ({ account }) => {
  return (
    <ul className="d-flex align-items-center justify-content-end h-100 list-unstyled  mb-0 gap-3">
      <li className="ms-2">
        <NavLink
          to="/"
          className={(navData) =>
            navData.isActive
              ? `${classes.active} text-decoration-non`
              : "text-dark text-decoration-none"
          }
        >
          Home
        </NavLink>
      </li>
      <li className="ms-2">
        <NavLink
          to="/nftmembership"
          className={(navData) =>
            navData.isActive
              ? `${classes.active} text-decoration-non`
              : "text-dark text-decoration-none"
          }
        >
          Membership
        </NavLink>
      </li>

      <li className="ms-2">
        <NavLink
          to="/projects"
          className={(navData) =>
            navData.isActive
              ? `${classes.active} text-decoration-non`
              : "text-dark text-decoration-none"
          }
        >
          Projects
        </NavLink>
      </li>
      {account && admin[account] && (
        <li className="ms-2">
          <NavLink
            to="/addproject"
            className={(navData) =>
              navData.isActive
                ? `${classes.active} text-decoration-non`
                : "text-dark text-decoration-none"
            }
          >
            Add project
          </NavLink>
        </li>
      )}
      {account && (
        <li className="ms-2">
          <NavLink
            to={`/userwallet`}
            className={(navData) =>
              navData.isActive
                ? `${classes.active} text-decoration-non`
                : "text-dark text-decoration-none"
            }
          >
            My Wallet
          </NavLink>
        </li>
      )}

      <li className="ms-2">
        <NavLink
          to="/about"
          className={(navData) =>
            navData.isActive
              ? `${classes.active} text-decoration-non`
              : "text-dark text-decoration-none"
          }
        >
          About
        </NavLink>
      </li>
    </ul>
  );
};

export default Nav;
