import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  accountState,
  currentNetworkState,
  existingWalletState,
  isLoggedInState,
  nftWalletState,
  web3State,
  wrongNetworkState,
} from "../../recoil/atoms";
import useAccount from "../../hooks/useAccount";
import { useEffect } from "react";
import nftAbi from "../../data/Abis/nftContractAbi.json";
import UserClass from "./UserClass";
import { useNetwork } from "../../hooks/useNetwork";
import CurrentAccount from "./CurrentAccount";
import HeaderMenu from "./HeaderMenu";
import { AiOutlineMenu } from "react-icons/ai";
import { useState } from "react";
import WalletMsg from "./WalletMsg";
import useAuth from "../../hooks/useAuth";
import { SlLogout } from "react-icons/sl";
import { useCallback } from "react";
import classes from "./Header.module.css";
import MemberMessage from "./MemberMessage";
import bronze from "../../assets/bronze.png";
import silver from "../../assets/silver.png";
import gold from "../../assets/gold.png";
import Nav from "./Nav";
import logo from "../../assets/logo-open.png";
import { AppParams } from "../../config";

const Header = () => {
  const { logOut, logIn } = useAuth();
  const web3 = useRecoilValue(web3State);
  useAccount(web3);
  useNetwork();
  const { account, accountIsReady } = useRecoilValue(accountState);
  const { existingWallet, isReady: walletIsReady } =
    useRecoilValue(existingWalletState);
  const [nftWallet, setNftWallet] = useRecoilState(nftWalletState);
  const [wrongNetwork, setWrongNetwork] = useRecoilState(wrongNetworkState);
  const { isReady, network: currentNetwork } =
    useRecoilValue(currentNetworkState);
  const [showMenu, setShowMenu] = useState(false);
  const [showWalletMessage, setShowWalletMessage] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  useEffect(() => {
    setIsLoggedIn(JSON.parse(window.localStorage.getItem("isLoggedIn")));
  }, [setIsLoggedIn]);

  const checkNetwork = useCallback(async () => {
    if (isReady && currentNetwork.chainId !== AppParams.NETWORK.chainId) {
      setWrongNetwork(true);
    } else {
      setWrongNetwork(false);
    }
  }, [currentNetwork, isReady, setWrongNetwork]);

  useEffect(() => {
    checkNetwork();
  }, [currentNetwork, isReady, checkNetwork]);

  const getData = useCallback(async () => {
    setNftWallet((prevState) => {
      return { ...prevState, isLoading: true, isReady: false };
    });
    if (isReady && wrongNetwork) {
      setNftWallet((prevState) => {
        return {
          ...prevState,

          isReady: true,
          isLoading: false,
        };
      });
      return;
    }

    if (account && accountIsReady) {
      try {
        const nftContract = new web3.web3.eth.Contract(
          nftAbi,
          AppParams.NETWORK.nftContractAddress
        );

        const wallet = await nftContract.methods.wallet(account).call();
        setNftWallet((prevState) => {
          return {
            ...prevState,
            wallet: {
              bronze: parseInt(wallet["bronze"]),
              silver: parseInt(wallet["silver"]),
              gold: parseInt(wallet["gold"]),
            },
            isReady: true,
            isLoading: false,
          };
        });
      } catch (error) {
        setNftWallet((prevState) => {
          return {
            ...prevState,
            isLoading: false,
            isReady: true,
          };
        });
      }
    }
  }, [account, wrongNetwork, accountIsReady]);
  useEffect(() => {
    getData();
  }, [account, isReady, setNftWallet, wrongNetwork, getData]);

  useEffect(() => {
    if (!existingWallet && walletIsReady) {
      setShowWalletMessage(true);
    }
  }, [existingWallet, walletIsReady]);

  return (
    <>
      <header
        className={` shadow-sm position-fixed top-0 left-0 pt-1 w-100 mx-auto bg-white ${classes.header} `}
      >
        {
          <section className="position-relative d-flex  zoomM justify-content-between align-items-center container-fluid py-2">
            <div>
              <Link as={Link} to="/">
                <img src={logo} width="130" alt="Open2be" />
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <nav className="ms-auto text-center w-auto d-none d-md-block me-4">
                <Nav account={account} />
              </nav>
              <div className="d-flex justify-content-end align-items-center">
                {!web3.isLoading &&
                  web3.web3 &&
                  !account &&
                  accountIsReady &&
                  existingWallet &&
                  !isLoggedIn && (
                    <Button
                      className={`btn  bgB  btn-sm d-block `}
                      onClick={() => {
                        logIn();
                      }}
                    >
                      Connect Wallet
                    </Button>
                  )}

                {account && accountIsReady && (
                  <div className="d-flex  align-items-center waletNft">
                    {nftWallet.isReady &&
                      !wrongNetwork &&
                      (nftWallet.wallet.bronze !== 0 ||
                        nftWallet.wallet.silver !== 0 ||
                        nftWallet.wallet.gold !== 0) && (
                        <UserClass
                          image={
                            nftWallet.wallet.bronze &&
                            !nftWallet.wallet.silver &&
                            !nftWallet.wallet.gold
                              ? bronze
                              : nftWallet.wallet.silver &&
                                !nftWallet.wallet.gold
                              ? silver
                              : nftWallet.wallet.gold
                              ? gold
                              : null
                          }
                          text={
                            nftWallet.wallet.bronze &&
                            !nftWallet.wallet.silver &&
                            !nftWallet.wallet.gold
                              ? "Bronze"
                              : nftWallet.wallet.silver &&
                                !nftWallet.wallet.gold
                              ? "Silver"
                              : nftWallet.wallet.gold
                              ? "Gold"
                              : ""
                          }
                        />
                      )}
                    {!web3.isLoading && account && <CurrentAccount />}
                  </div>
                )}

                {isLoggedIn && account && (
                  <Button
                    className={`btn  bgB  btn-sm d-block ms-2 `}
                    onClick={logOut}
                  >
                    Logout <SlLogout />
                  </Button>
                )}
                <Button
                  className={`${classes["menu-btn"]} d-md-none btn-sm -primary`}
                  onClick={() => {
                    setShowMenu((prevState) => {
                      return !prevState;
                    });
                  }}
                >
                  <AiOutlineMenu />
                </Button>
              </div>
            </div>
            <HeaderMenu
              show={showMenu}
              onClick={() => {
                setShowMenu(false);
              }}
            />
            <WalletMsg
              message={"Please Install Metamask"}
              show={showWalletMessage}
              onHide={() => {
                setShowWalletMessage(false);
              }}
              text={"Install Metamsk"}
            />
          </section>
        }

        <MemberMessage />
      </header>
    </>
  );
};

export default Header;
