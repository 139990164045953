import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router";

import classes from "./Collection.module.css";

const imageUrl =
  "https://res.cloudinary.com/decpwvhfk/image/upload/v1660003861/images_errvjn.png";

const CollectionNft = ({ tokenCategory, project, tokenId }) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(`/nfts/${project.id}/${tokenCategory.id}`);
  };

  const projectClickHandler = () => {
    navigate(`/project/${project.tokenName}`);
  };
  return (
    <Card>
      <Card.Body>
        <Card.Img src={imageUrl} alt={tokenCategory.name} />
        <div className="d-flex justify-content-between align-items-center p-1">
          <p className="p-0 m-0">Token Name:</p>
          <p
            className={`${classes.link} p-0 m-0`}
            onClick={projectClickHandler}
          >
            {project.tokenName}
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center p-1">
          <p className="p-0 m-0">Token Category:</p>
          <p className={`${classes.link} p-0 m-0`} onClick={clickHandler}>
            {tokenCategory.name}
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center p-1">
          <p className="p-0 m-0">Token ID:</p>
          <p className="p-0 m-0">{tokenId}</p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CollectionNft;
