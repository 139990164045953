import { useCallback } from "react";
import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import networks from "../data/networks";
import { currentNetworkState, web3State } from "../recoil/atoms";
const useNetwork = () => {
  const web3Api = useRecoilValue(web3State);
  const setCurrentNetwork = useSetRecoilState(currentNetworkState);

  const getId = useCallback(async () => {
    if (web3Api.web3) {
      const chainId = await web3Api.web3.eth.getChainId();

      setCurrentNetwork((prevState) => {
        return {
          ...prevState,
          network: {
            ...networks[parseInt(chainId)],
          },
          isReady: true,
        };
      });
    }
  }, [web3Api, setCurrentNetwork]);

  useEffect(() => {
    getId();
  }, [web3Api.web3, getId]);
  return { getId };
};

export { useNetwork };
