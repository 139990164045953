import React from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ImageContainer from "../components/Nft details page/ImageContainer";
import Footer from "../components/Footer";
import NftDetails from "../components/Nft details page/NftDetails";
import { useParams } from "react-router-dom";
import { useCallback } from "react";
import { createClient } from "urql";
import { AppParams } from "../config";
import { useState } from "react";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import { useRecoilValue } from "recoil";
import { accountState, wrongNetworkState } from "../recoil/atoms";
import SimpleMessage from "../components/SimpleMessage";

const imageUrl =
  "https://res.cloudinary.com/decpwvhfk/image/upload/v1660003861/images_errvjn.png";

const graphClient = createClient({ url: AppParams.GRAPH_URL });
const ProjectNftsPage = () => {
  const { id, projectId } = useParams();
  const [category, setCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const { account, accountIsReady } = useRecoilValue(accountState);
  const wrongNetwork = useRecoilValue(wrongNetworkState);

  useEffect(() => {
    if (wrongNetwork && account && accountIsReady) {
      setShowModal(true);
      setMessage(`Please turn to ${AppParams.NETWORK.chainName} to buy NFTs`);
    }
  }, [wrongNetwork, account, accountIsReady]);

  useEffect(() => {
    if (!account && accountIsReady) {
      setShowModal(true);
      setMessage(`Only connected users can buy NFTs`);
    }
  }, [account, accountIsReady]);

  const getDetails = useCallback(async () => {
    setIsLoading(true);
    setSuccess(false);
    setError("");
    const query = `{
 tokenCategories(where:{id:"${id}"}){
  id,
  categoryId
currentSupp,
maxSupply,
price,
name}
}`;

    try {
      const response = await graphClient.query(query).toPromise();

      if (response.error) {
        throw new Error(`Something went wrong, please try later`);
      }
      setIsLoading(false);
      setSuccess(true);
      setCategory(response.data.tokenCategories[0]);
    } catch (error) {
      setError("Something went wrong, please try later");
      setIsLoading(false);
    }
  }, [id]);
  useEffect(() => {
    getDetails();
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }
  return (
    <>
      {success && category && (
        <>
          {" "}
          <Container fuild="lg">
            <Row className=" pt-5 gy-4 section mt-3">
              <Col md={6}>
                <ImageContainer imageUrl={imageUrl} />
              </Col>
              <Col
                md={6}
                className="justify-content-center align-items-center h-100"
              >
                <NftDetails {...category} projectId={projectId} />
              </Col>
            </Row>
          </Container>
          <Footer />
        </>
      )}
      {showModal && (
        <SimpleMessage
          show={showModal}
          message={message}
          onHide={() => {
            setShowModal(false);
            setMessage("");
          }}
        />
      )}
      {success && !category && <ErrorPage />}
    </>
  );
};

export default ProjectNftsPage;
