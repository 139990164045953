import BigNumber from "bignumber.js";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import numberFixDecimals from "../../utils/numberFixDecimals";
import addZero from "../../utils/timeAddZero";
import classes from "./UserWallet.module.css";
import { AppParams } from "../../config";
const imageUrl =
  "https://res.cloudinary.com/decpwvhfk/image/upload/v1660003861/images_errvjn.png";
const WalletItem = ({ project, tokenCategory, createdAt, price }) => {
  const navigate = useNavigate();
  const categoryClickHandler = () => {
    navigate(`/nfts/${project.id}/${tokenCategory.id}`);
  };
  return (
    <Row
      className={`${classes.item} d-flex justify-content-between my-1 rounded p-2 border-1 align-items-center`}
    >
      <Col sm="12" md="2">
        <div className=" d-flex">
          <p className="d-md-none me-2">Token:</p>
          <p
            onClick={() => {
              navigate(`/project/${project.tokenName}`);
            }}
            className={`${classes.link} tokenTa`}
          >
            <img src={imageUrl} width="40" className="me-1" alt="logo" />
            {project.tokenName}
          </p>
        </div>
      </Col>
      <Col sm="2">
        <div className="d-flex">
          <p className="d-md-none me-2">Category</p>
          <p className={classes.link} onClick={categoryClickHandler}>
            {tokenCategory.name}
          </p>
        </div>
      </Col>
      <Col sm="12" md="2">
        <div className=" d-flex">
          <p className="d-md-none me-2">Token:</p>
          <p>{`${addZero(
            new Date(new Date(createdAt * 1000).getTime()).getDate()
          )}-${addZero(
            new Date(new Date(createdAt * 1000).getTime()).getMonth() + 1
          )}-${new Date(
            new Date(createdAt * 1000).getTime()
          ).getFullYear()} ${addZero(
            new Date(new Date(createdAt * 1000).getTime()).getHours()
          )}:${addZero(
            new Date(new Date(createdAt * 1000).getTime()).getMinutes()
          )} 
`}</p>
        </div>
      </Col>
      <Col sm="12" md="2">
        <div className="d-flex">
          <p className="me-2 d-md-none">Price:</p>
          <p>
            {new BigNumber(tokenCategory.price.toString())
              .dividedBy(
                new BigNumber("10").pow(
                  new BigNumber(
                    AppParams.NETWORK.platformToken.decimals.toString()
                  )
                )
              )
              .toFixed(
                numberFixDecimals(
                  new BigNumber(price.toString())
                    .dividedBy(
                      new BigNumber("10").pow(
                        new BigNumber(
                          AppParams.NETWORK.platformToken.decimals.toString()
                        )
                      )
                    )
                    .toNumber()
                )
              )
              .toString()}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default WalletItem;
