import React from "react";
import { useRecoilValue } from "recoil";
import { accountState } from "../../recoil/atoms";
import classes from "./CurrentAccount.module.css";
const CurrentAccount = () => {
  const { account } = useRecoilValue(accountState);
  return (
    <>
      {account && (
        <>
          <div className={`${classes.container} ms-2`}>
            <a
              href={`https://etherscan.io/address/${account}`}
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none linkW"
            >{`${account.slice(0, 9)}...`}</a>

            <a
              href={`https://etherscan.io/address/${account}`}
              target="_blank"
              rel="noreferrer"
              className={`${classes.tooltip} rounded p-1 font-weight-500 currentA`}
            >
              {account}
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default CurrentAccount;
