import React from "react";
import { Col, Row } from "react-bootstrap";
import WalletItem from "./WalletItem";
import classes from "./UserWallet.module.css";
import Loader from "../../UI/Loader";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { createClient } from "urql";
import { AppParams } from "../../config";
import { useRecoilValue } from "recoil";
import { accountState } from "../../recoil/atoms";

const client = createClient({ url: AppParams.GRAPH_URL });
function PurchaseHistory() {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [history, setHistory] = useState(null);
  const [error, setError] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [itemsPerList, setItelPerList] = useState(2);

  const { account } = useRecoilValue(accountState);

  const getHistory = useCallback(async () => {
    setIsLoading(true);
    setError("");
    setSuccess(false);
    const query = `{
 sales(first:${itemsPerList},where:{purchaser:"${account.toLowerCase()}"},orderBy:createdAt,orderDirection:desc){
    id,
    createdAt,
    price
  project{tokenName,id}
  tokenCategory{categoryId,name,price,id,contract {
    id
  }}
  tokenId
  purchaser{id,purchaseCount}
}
}`;

    try {
      const response = await client.query(query).toPromise();

      if (response.error) {
        throw new Error("omething went wrong,please try later");
      }
      setHistory(response.data.sales);
      setNumberOfItems(
        response.data.sales[0]
          ? response.data.sales[0].purchaser.purchaseCount
          : 0
      );
      setIsLoading(false);
      setSuccess(true);
    } catch (error) {
      setError("Something went wrong please try later");
      setIsLoading(false);
    }
  }, [account, itemsPerList]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  const clickHandler = () => {
    if (itemsPerList < numberOfItems) {
      setItelPerList((prevState) => parseInt(prevState) + 1);
    }
  };

  return (
    <section className="px-0 mx-auto">
      <h2 className="mt-5 mb-3">Purchase history</h2>
      <div className="tableHi">
        <Row
          className={`${classes.header} d-none d-md-flex justify-content-between  align-items-center mx-auto`}
        >
          <Col sm="2">
            <small>NFT</small>
          </Col>
          <Col sm="2">
            <small>Category</small>
          </Col>
          <Col sm="2">
            <small> Date</small>
          </Col>
          <Col sm="2">
            <small>Price ({AppParams.NETWORK.platformToken.symbol})</small>
          </Col>
        </Row>

        {isLoading && (
          <Row className="p-3 my-3 mx-auto">
            <span className="d-flex w-100 justify-content-center align-items-center">
              <Loader width={26} height={26} dark />
            </span>
          </Row>
        )}

        <Row className="m-0 justify-content-center">
          {success && history && history.length !== 0 && (
            <>
              {history.map((sale) => {
                return <WalletItem key={sale.id} {...sale} />;
              })}
              {itemsPerList < numberOfItems && (
                <div className="w-100  d-flex justify-content-center align-items-center">
                  <p className={classes.see} onClick={clickHandler}>
                    See more
                  </p>
                </div>
              )}
            </>
          )}
        </Row>
        {success && history && history.length === 0 && (
          <Row className="p-3 my-3 mx-auto">
            <p className="text-center">You didn't buy any NFT yet!</p>
          </Row>
        )}
        {error && (
          <Row className="p-3 my-3 mx-auto">
            <p className=" text-center text-danger p-1">
              Something went wrong, we can't get your purchase history, please
              try later
            </p>
          </Row>
        )}
      </div>
    </section>
  );
}

export default PurchaseHistory;
