import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../components/Footer";
import PurchaseHistory from "../components/wallet/PurchaseHistory";
import Collection from "../components/wallet/Collection";

const UserWallet = () => {
  return (
    <>
      <section className="justify-centent-center align-items-start section pt-5">
        <div className="bgTitle">
          <h1 className="text-white">My wallet</h1>
        </div>
        <Container fluid="lg">
          <Collection />
          <PurchaseHistory />
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default UserWallet;
