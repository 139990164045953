import React from "react";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import admin from "../../data/admin.json";
import { accountState } from "../../recoil/atoms";
import classes from "./HeaderMenu.module.css";

const HeaderMenu = ({ show, onClick }) => {
  const { account } = useRecoilValue(accountState);

  return (
    <ul
      className={
        show
          ? `${classes.show} ${classes.menu} list-unstyled pt-2`
          : `${classes.menu} ${classes.hidden} list-unstyled d-md-none`
      }
    >
      {" "}
      <li className="ms-2">
        <NavLink
          to="/"
          className={(navData) =>
            navData.isActive
              ? `${classes.active} text-decoration-non`
              : "text-dark text-decoration-none"
          }
          onClick={onClick}
        >
          Home
        </NavLink>
      </li>
      <li className="ms-2">
        <NavLink
          to="/nftmembership"
          className={(navData) =>
            navData.isActive
              ? `${classes.active} text-decoration-non`
              : "text-dark text-decoration-none"
          }
          onClick={onClick}
        >
          Membership
        </NavLink>
      </li>
      {account && admin[account] && (
        <li className="ms-2">
          <NavLink
            to="/addproject"
            className={(navData) =>
              navData.isActive
                ? `${classes.active} text-decoration-non`
                : "text-dark text-decoration-none"
            }
            onClick={onClick}
          >
            Add project
          </NavLink>
        </li>
      )}
      <li className="ms-2">
        <NavLink
          to="/projects"
          className={(navData) =>
            navData.isActive
              ? `${classes.active} text-decoration-non`
              : "text-dark text-decoration-none"
          }
          onClick={onClick}
        >
          Projects
        </NavLink>
      </li>
      {account && (
        <li className="ms-2">
          <NavLink
            to={`/userwallet`}
            className={(navData) =>
              navData.isActive
                ? `${classes.active} text-decoration-non`
                : "text-dark text-decoration-none"
            }
          >
            My Wallet
          </NavLink>
        </li>
      )}
      <li className="ms-2">
        <NavLink
          to="/about"
          className={(navData) =>
            navData.isActive
              ? `${classes.active} text-decoration-non`
              : "text-dark text-decoration-none"
          }
          onClick={onClick}
        >
          About
        </NavLink>
      </li>
    </ul>
  );
};

export default HeaderMenu;
