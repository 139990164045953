import { useCallback, useEffect } from "react";
import { accountState, isLoggedInState } from "../recoil/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import useWeb3 from "./useWeb3";

const useAccount = (web3Api) => {
  const { loadProvider } = useWeb3();
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const setAccount = useSetRecoilState(accountState);
  const getAccount = useCallback(async () => {
    if (web3Api && web3Api.web3 && web3Api.isReady) {
      const accounts = await web3Api.web3.eth.getAccounts();
      if (isLoggedIn && accounts.length) {
        setAccount((prevState) => {
          return {
            ...prevState,
            account: accounts[0],
            accountIsReady: true,
            isLoading: false,
          };
        });
      } else if (!accounts.length && isLoggedIn) {
        setAccount((prevState) => {
          return {
            ...prevState,
            account: null,
            accountIsReady: true,
            isLoading: false,
          };
        });
        setIsLoggedIn(false);
      } else if (!isLoggedIn) {
        setAccount((prevState) => {
          return {
            ...prevState,
            account: null,
            accountIsReady: true,
            isLoading: false,
          };
        });
      }
    } else if (web3Api && !web3Api.web3 && web3Api.isReady) {
      setAccount((prevState) => {
        return {
          ...prevState,
          account: null,
          accountIsReady: true,
          isLoading: false,
        };
      });
    }
  }, [isLoggedIn, setAccount, web3Api, setIsLoggedIn]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async () => {
        loadProvider();
        getAccount();
      });
      window.ethereum.on("chainChanged", (chainId) => {
        getAccount();
        window.location.reload();
      });
    }
  }, []);

  useEffect(() => {
    getAccount();
  }, [isLoggedIn, getAccount, web3Api, setIsLoggedIn, isLoggedIn]);
  return { getAccount };
};

export default useAccount;
