import { atom } from "recoil";

//web 3 atoms
export const existingWalletState = atom({
  key: "existingWallet",
  default: { existingWallet: null, isReady: null },
});

export const accountState = atom({
  key: "account",
  default: { account: null, accountIsReady: null, isLoading: null },
});

export const isLoggedInState = atom({
  key: "isLoggedIn",
  default: JSON.parse(window.localStorage.getItem("isLoggedIn")),
});

export const web3State = atom({
  key: "web3State",
  default: {
    provider: null,
    web3: null,
    isLoading: false,
    isReady: null,
  },
  dangerouslyAllowMutability: true,
});

//networks state
export const currentNetworkState = atom({
  key: "currentNetwork",
  default: {
    network: {
      chainId: 0,
      platformToken: "",
      tokenFactoryAddress: "",
      whitelistFactoryAddress: "",
      swapTokenFactoryAddress: "",
      nftContractAddress: "",
    },
    isReady: null,
  },
});

export const wrongNetworkState = atom({ key: "wrongNetwork", default: null });

//all projects
export const projectsState = atom({ key: "projects", default: [] });
//filter term
export const filterTermState = atom({ key: "filterTerm", default: "all" });
export const numberOfPagesState = atom({ key: "numberOfPages", default: 1 });
export const currentPageState = atom({ key: "CurrentPage", default: 1 });

//contracts
//platform token contract
export const platformTokenContractState = atom({
  key: "pltaformtokenContract",
  default: "",
  dangerouslyAllowMutability: true,
});
//whitelistContract
export const whitelistContractState = atom({
  key: "whitelistContract",
  default: {},
  dangerouslyAllowMutability: true,
});

//ido contract
export const idoContractState = atom({
  key: "idoContract",
  default: "",
  dangerouslyAllowMutability: true,
});
//single project atoms
export const whitelistState = atom({
  key: "whitelist",
  default: { isLoading: null, error: null, success: null },
});
export const isWhitedlistedState = atom({
  key: "isWhitelisted",
  default: null,
});
export const projectState = atom({ key: "project", default: {} });

export const nftWalletState = atom({
  key: "nftWallet",
  default: {
    wallet: { bronze: 0, silver: 0, gold: 0 },
    isReady: null,
    isLoading: null,
  },
});
