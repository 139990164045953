import Web3 from "web3";
import {
  existingWalletState,
  isLoggedInState,
  web3State,
} from "../recoil/atoms";
import { useSetRecoilState, useRecoilState } from "recoil";
import { useCallback, useEffect } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
function useWeb3() {
  const setExistingWallet = useSetRecoilState(existingWalletState);
  const [web3, setWeb3] = useRecoilState(web3State);
  const setIsLoggedIn = useSetRecoilState(isLoggedInState);
  //check if letamask is installekd
  const loadProvider = useCallback(async () => {
    const provider = await detectEthereumProvider();
    if (provider) {
      const web3 = new Web3(provider);
      setExistingWallet((prevState) => {
        return { ...prevState, existingWallet: true, isReady: true };
      });
      setWeb3((prevState) => ({
        ...prevState,
        web3: web3,
        provider,
        isLoading: false,
        isReady: true,
      }));
    } else {
      setExistingWallet((prevState) => {
        return { ...prevState, existingWallet: false, isReady: true };
      });
    }
  }, [setWeb3, setExistingWallet]);
  useEffect(() => {
    loadProvider();
  }, [setWeb3, setExistingWallet, loadProvider]);
  async function connectWallet() {
    if (web3.provider) {
      try {
        setIsLoggedIn(true);
        // open metamask to connect
        await web3.provider.request({ method: "eth_requestAccounts" });
        setIsLoggedIn(true);
      } catch (error) {
        setIsLoggedIn(false);
        console.log(error.message);
        window.location.reload();
      }
    }
  }

  return { connectWallet, loadProvider };
}
export default useWeb3;
