import React from "react";
import { Button, Modal } from "react-bootstrap";

const WalletMsg = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{ textAlign: "center" }}>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="outline-primary">
          close
        </Button>
        <Button onClick={props.OnClick} className="bgB">
          <a
            href="https://metamask.io/"
            target="_blank"
            rel="noreferrer"
            className=" text-light text-decoration-none"
          >
            {props.text}
          </a>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WalletMsg;
